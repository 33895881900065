@import "../../styles/variables";

.quizcontainer {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: $c-primary;
  transition: color 0.3s $e-inout;
  flex: 1;
  @media (orientation: landscape) {
    justify-content: center;
  }
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // height: 100%;
  // padding-top: $headerHeight;
  &:before {
    transition: opacity 0.3s $e-inout;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, $c-primary 81.54%, darken($c-primary, 10%) 100%), $c-primary;
    opacity: 0;
    // z-index: 0;
  }
}

.question {
  // min-height: 100%;
//  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  padding: 0 $s-bodypadding-sm;
  @media (min-width: $mq-md) {
    padding: 0 $s-bodypadding-md;
  }
  @media (min-width: $mq-lg) {
    padding: 0 $s-bodypadding-lg;
  }
  padding-bottom: 0;
  @media(orientation: portrait){
    padding-bottom: 0;
  }
  // padding-bottom: 160px;
  // @media(orientation: portrait){
  //   padding-bottom: 100px;
  // }
}

.temp-button {
  background: $c-white;
  padding: 1em 2em;
  color: $c-primary;
  border: none;
  margin-top: $s-large;
  &:focus {
    outline: none;
  }
}

.countdown-timer {
  text-align: right;
  position: fixed;
  z-index: 1000;
  bottom: 5px;
  left: 0;
  right: 0;
  height: 50px;
  font-size: 1.2rem;
  @media(orientation: portrait){
    font-size: 1rem;
    height: 30px;
    bottom: 20px;
  }
  display: flex;
  padding-right: $s-bodypadding-sm;
  justify-content: flex-end;
  align-items: center;
}
