@import "../../styles/variables";

.view-registration {
  --color: #{$c-primary};
  --color-inverted: #{$c-white};

  width: 100%;
  height: auto;
  flex: 1;
  padding-bottom: $s-medium;
  position: relative;
  z-index: 10;
  padding-top: $s-large;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: $mq-md){
    padding-top: $s-small;
  }

  &__title {
    margin: $s-medium $s-small 0;
  }
  &__logo {
    background: $c-primary;
    padding: $s-medium calc(#{$s-medium} * 1.1);
    margin-bottom: $s-large;
    width: 100%;
    display: inline-block;
    line-height: 0;

    @media(max-width: $mq-md){
      padding: calc(#{$s-small} * 1.2);
      margin-bottom: $s-medium;
    }

    svg {
      width: 100%;
    }
  }

  &__url{
    padding: $s-large 0 $s-large;
    font-size: 30px;
  }
}

// .boxes {
//   margin: $s-large 2% 0;
//   @media (min-width: $mq-md) {
//     display: flex;
//   }
//   .box {
//     display: flex;
//     flex-direction: column;
//     margin: 2% 1%;
//     padding: 5% 4%;
//     @media (min-width: $mq-md) {
//       flex: 1 0 (33.332% - 1% * 3);
//       padding: 2% 2%;
//     }
//     transition: border-color 0.3s $e-inout;
//     border: 1px solid var(--color);
//     .VaporButtons {
//       margin: auto;
//       font-size: 10vw;
//       @media (min-width: $mq-md) {
//         font-size: 3vw;

//       }
//     }
//   }
//}

// .color-row {
//   margin-top: $s-small;
//   padding: 0.6em;
//   text-align: center;
//   font-weight: bold;
// }

// .color-row + .color-row {
//   margin-top: 0;
// }