@import "../../styles/variables";

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: $s-medium $s-bodypadding-sm;

  @media (min-width: $mq-md) {
    padding: $s-medium $s-bodypadding-md;
  }

  @media (min-width: $mq-lg) {
    padding: $s-medium $s-bodypadding-lg;
  }
  // @media (max-height: 400px) {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  // }

  

  &__logo {
    color: inherit;
    width: 115px;
    max-width: 10em;
    margin-top: 5px;
    svg {
      width: 100%;
      height: auto;
    }

    @media (orientation: portrait) {
      width: 90px;
    }
  }
}
