html, body, #root, .quizcontainer {
  height: auto !important;
}

body {
  overflow: auto !important;
}

.question {
  height: 80vh;
  border-bottom: inset 1px black;
  margin-bottom: 75px;
}