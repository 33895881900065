@import '../../styles/variables';

.chooseimage {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  &__column {
    padding: 0;
  }
  &__image {
    width: 40vw;
    height: 40vw;
    @media (orientation: landscape) {
      width: 22vw;
      height: 22vw;
    }
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    -webkit-clip-path: inset(6% 6% 6% 6% round 3%);
    clip-path: inset(6% 6% 6% 6% round 3%);

    // fallback
    border-radius: 0%;
    overflow: hidden;
    @media (orientation: landscape) {
      -webkit-clip-path: inset(6% 6% 6% 6% round 3%);
      clip-path: inset(6% 6% 6% 6% round 3%);
    }
    position: relative;
  }
  // &__input:checked + .chooseimage__image {
  //   outline: 2px solid green;
  // }
}