@import "../../styles/variables";

.button {
  --color: #{$c-primary};
  --color-inverted: #{$c-white};

  background: var(--color);
  padding: 0.6em 2em;
  color: var(--color-inverted);
  border: none;
  display: inline-block;
  font-weight: 900;
  text-align: center;
  min-width: 180px;
  font-size: $f-body-size;
  border: 1px solid $c-primary;  
  cursor: pointer;
  transition: transform $t-base $e-out, box-shadow $t-base $e-out, background-color 0.3s $e-inout, color 0.3s $e-inout;
  box-shadow: 0 0 0 0 rgba($c-primary,0.1); // for focus state
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba($c-primary,0.2);
  }

  &--center {
    margin-left: auto;
    margin-right: auto;
  }
  &--white {
    --color: #{$c-white};
    --color-inverted: #{$c-primary};
    border: 1px solid $c-primary;
  }

  @media (hover: hover) {
    &:hover{
      transform: translateY(2px);
    }
  }
}

.vapormode .button {
  --color: #{$c-white};
  --color-inverted: #{$c-primary};
}

.button.button--small {
  padding: 0.4em 1em;
  font-size: $f-body-small;
}