@import "../styles/variables";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  pointer-events: none;
  z-index: 10;
  &--underlay {
    z-index: 0;
    // z-index: 20; // for dev
  }

  &--levelupeffect {
    z-index: 10;
    transition: background-color 0.3s $e-inout;
    background-color: rgba($c-white, 0);
  }
  &--playing {
    &.overlay--levelupeffect {
      background-color: rgba($c-white, 1);
    }
  }

  &--success, &--timeup {
    // Special case for preventing line-breaks
    @media(max-width: 500px){
      .heading-background {
        &:before {
          width: 4rem;
        }
        .h1 {
          font-size: calc(50px + 1vw);
        }
      }
    }
  }

  &--answer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $c-primary;
    .content-wrapper {
      z-index: 2;
      text-align: center;
      // transform: translateY(-50%);
    }
  }

  &--white {
    background: $c-white;
  }

  .overlay-icon {
    max-width: 80vw;
    margin: auto;
    @media (min-width: $mq-md) {
      max-width: 38vw;
    }
  }

  // &__header {
  //   opacity: 0;
  //   transform: translateX(50px);
  //   pointer-events: none;
  //   transition: opacity 0.2s $e-in, transform 0.2s $e-in;
  //   &--show {
  //     opacity: 1;
  //     transform: translateX(0);
  //     transition: opacity 0.6s $e-out, transform 0.6s $e-out;
  //   }
  // }
}