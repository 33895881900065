@import '../../styles/variables';

.pair {
  --color: #{$c-primary};
  --color-inverted: #{$c-white};

  display: flex;
  flex-wrap: wrap;

  &__option {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $s-small / 2 0;
    flex-basis: 100%;
    @media(orientation: landscape){
      flex-basis: 50%;
      padding: $s-small $s-small;
    }

    &__button {
      // @media(min-width: $mq-sm){
      //   padding: $s-large $s-medium;
      // }
      min-width: 90px;
      max-width: 350px;
      border: 1px solid $c-primary;
      border: 1px solid var(--color);
      border-radius: 100px;
      overflow: hidden;
      position: relative;
      background-color: $c-white;
      background-color: $c-neutral-1;
      color: $c-primary;
      color: var(--color);
      padding: $s-medium;
      transition: background-color 0.3s $e-inout, color 0.3s $e-inout, box-shadow 0.3s;
      // padding: $s-medium $s-medium;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      cursor: pointer;
      // text-transform: uppercase;
      text-align: center;

      &:focus-within {
        box-shadow: 0 0 0 5px rgba($c-primary, 0.3);
      }

      @media(orientation: portrait){
        padding: $s-small;
      }

      .text {
        z-index: 2;
        .svg-checked {
          position: absolute;
          right: $s-small;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.4s;
          &.matched {
            opacity: 1;
          }
        }
      }

      &.selected {
        background-color: $c-primary;
        background-color: var(--color);
        color: $c-white;
        color: var(--color-inverted);
        cursor: inherit;
      }

    }
  }
}