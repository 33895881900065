@import '../../styles/variables';

.sort {
  --color: #{$c-primary};
  --color-inverted: #{$c-white};

  user-select: none;
  margin-top: $s-medium;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 60px; //For icon
  flex: 1;

  @media(orientation: portrait) {
    flex: 1;
    width: 100%;
    justify-content: space-between;
  }

  &__category {
    border: 1px dashed #435A69;
    width: 35vw;
    height: 35vw;
    background-color: $c-white;
    transition: background-color 0.3s $e-out, transform 0.3s $e-out;
    font-weight: bold;
    @media (orientation: portrait) {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      padding-left: 5vw;
      margin-left: -28vw;
      align-items: flex-start;
      padding: $s-small;
      height: 70vw;
      &--2 {
        transform: rotate(180deg);
        margin-left: 0;
        margin-right: -28vw;
      }
    }
    @media(orientation: landscape) {
      width: 20vw;
      height: 25vw;
      font-size: 28px;
    }
    border-radius: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    // Disabled dure to performence issues
    // &--active {
    //   transform: scale(1.1);
    //   @media (orientation: portrait) {
    //     &.sort__category--2 {
    //       transform: rotate(180deg) scale(1.1);
    //     }
    //   }
    // }
  }

  &__items {
    position: relative;
    z-index: 1; // move over categories
    width: 55vw;
    height: 70vw;
    @media(orientation: landscape) {
      margin: 10vw 0 3vw;
      width: 20vw;
      height: 27vw;
      margin: 0 5vw;
    }
  }
  // @keyframes tutorial {
  //   0% { transform: translateX(0)}
  //   20%, 40% { transform: translateX(20vw)}
  //   60%, 80% { transform: translateX(-20vw)}
  //   100% { transform: translateX(0)}
  // }
  @keyframes tutorial {
    0% { transform: translateX(0) rotate(0)}
    25% { transform: translateX(4vw) rotate(4deg)}
    65% { transform: translateX(-4vw) rotate(-4deg)}
    100% { transform: translateX(0) rotate(0)}
  }
  &__itemwrap--tutorial {
    position: relative;
    // animation: tutorial 2s ease-out 3.5s 1 forwards;
    animation: tutorial 1s ease-in-out 0.5s 1 forwards;
  }
  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 55vw;
    height: 70vw;
    padding: $s-medium;
    // word-break: break-all;
    // hyphens: auto;
    overflow: hidden;
    @media(orientation: landscape) {
      width: 20vw;
      height: 25vw;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column; // remove
    border-radius: 2%;
    background-color: #F8F8F8;
    border: 2px solid $c-primary;
    color: $c-primary;
    text-align: center;

    box-shadow: 0 6px 0 0 rgba($c-neutral-5, 0.03);
    // transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.6s ease;
    &:after {
      content: '';
      position: absolute;
      top: 10px;
      bottom: 10px;
      left: 10px;
      right: 10px;
      border: 1px solid $c-primary;
      border-radius: 2%;

      @media(orientation: portrait) {
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
      }
    }
    &:hover {
      cursor: move; // fallback
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }
    &--1 { top: 0 }
    &--2 { top: -4px }
    &--3 { top: -8px }
    &--4 { top: -12px }
    &--5 { top: -16px }
    &--6 { top: -20px }
    &--7 { top: -24px }
    &--8 { top: -28px }
    &--9 { top: -32px }
    &--10 { top: -36px }
    &--11 { top: -40px }
    &--12 { top: -44px }
    &--13 { top: -48px }
    &--14 { top: -52px }
    &--15 { top: -56px }
    &--16 { top: -60px }
    &--17 { top: -64px }
    &--18 { top: -68px }
    &--19 { top: -72px }
    &--20 { top: -76px }
    // TODO: Check if it the top item in the pile
    // &--top:hover {
    //   transform: scale(1.1);
    // }

    &--correct {
      // transform: scale(0.5);
      // opacity: 0;
      user-select: none;
    }

    h3 {
      font-size: 4vw;
      @media (orientation: landscape) {
        font-size: 2vw;
      }
    }
  }

  &__icon{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -60px;
  }
}