// HELPCLASSES
:root {
  .align-right {
    text-align: right
  }

  .align-left {
    text-align: left
  }

  .align-center {
    text-align: center
  }

  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .flex-center--row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .flex-expand{
    flex: 1;
  }

  .centeronmobile {
    @media (max-width: $mq-md) {
      margin: 0 auto;
    }
  }

  .centermargin {
    margin-left: auto;
    margin-right: auto;
  }

  .relative {
    position: relative;
  }

  .no-hyphens {
    hyphens: none;
  }

  .t-uc {
    text-transform: uppercase;
  }

  .float-right {
    float: right;
  }

  // Colors
  .c-primary {
    color: $c-primary
  }

  .c-secondary {
    color: $c-secondary;
  }

  .c-black {
    color: $c-black
  }

  .c-white {
    color: $c-white
  }

  .c-neutral-1,
  .c-bg {
    color: $c-neutral-1
  }

  .c-neutral-2 {
    color: $c-neutral-2
  }

  .c-neutral-3 {
    color: $c-neutral-3
  }

  .c-neutral-4 {
    color: $c-neutral-4
  }

  .c-neutral-5 {
    color: $c-neutral-5
  }

  .c-neutral-6,
  .c-dark {
    color: $c-neutral-6
  }

  .c-danger {
    color: $c-danger
  }

  .c-warning {
    color: $c-warning
  }

  .c-info {
    color: $c-info
  }

  .c-success {
    color: $c-success
  }

  // Background colors
  .primary {
    background-color: $c-primary;
    color: $c-white;
  }

  .primary_w40 {
    background-color: $c-primary_w40;
    color: $c-white;
  }
  .primary_w30 {
    background-color: $c-primary_w30;
    color: $c-white;
  }
  .primary_w20 {
    background-color: $c-primary_w20;
    color: $c-white;
  }
  .primary_b10 {
    background-color: $c-primary_b10;
    color: $c-white;
  }
  .primary_b20 {
    background-color: $c-primary_b20;
    color: $c-white;
  }
  .primary_b30 {
    background-color: $c-primary_b30;
    color: $c-white;
  }


  .black {
    background-color: $c-black;
    color: $c-white;
  }

  .white {
    background-color: $c-white;
    color: $c-neutral-6
  }

  .neutral-1 {
    background-color: $c-neutral-1;
    color: $c-neutral-6
  }

  .neutral-2,
  .light {
    background-color: $c-neutral-2;
    color: $c-neutral-6
  }

  .neutral-3 {
    background-color: $c-neutral-3;
    color: $c-neutral-6
  }

  .neutral-4 {
    background-color: $c-neutral-4;
    color: $c-white;
  }

  .neutral-5 {
    background-color: $c-neutral-5;
    color: $c-white;
  }

  .neutral-6,
  .dark {
    background-color: $c-neutral-6;
    color: $c-white;
  }

  .danger {
    background-color: $c-danger;
    color: $c-white;
  }

  .warning {
    background-color: $c-warning;
    color: $c-neutral-6
  }

  .info {
    background-color: $c-info;
    color: $c-white;
  }

  .success {
    background-color: $c-success;
    color: $c-white;
  }

  // Shadows
  .shadow-1 {
    box-shadow: $shadow-1
  }

  .shadow-2 {
    box-shadow: $shadow-2
  }

  // Spacing - space
  .s-right {
    margin-right: $s-xsmall
  }
  .s-right-m {
    margin-right: $s-medium
  }

  .s-left {
    margin-left: $s-xsmall
  }

  .s-bottom-m, .s-bottom-md {
    margin-bottom: $s-small;
    @media (min-width: $mq-md) {
      margin-bottom: $s-medium
    }
  }

  .s-bottom-s, .s-bottom-sm {
    margin-bottom: $s-small;
  }

  .s-top-sm {
    margin-top: $s-small;
  }


  .s-top-m, .s-top-md {
    margin-top: $s-small;
    @media (min-width: $mq-md) {
      margin-top: $s-medium
    }
  }

  .s-top-l, .s-top-lg {
    margin-top: $s-medium;
    @media (min-width: $mq-md) {
      margin-top: $s-large
    }
  }

  .s-bottom-l, .s-bottom-lg {
    margin-bottom: $s-medium;
    @media (min-width: $mq-md) {
      margin-bottom: $s-large
    }
  }

  // Spacing without Media Queries
  .s-top-s-womq {
    margin-top: $s-small;
  }

  .s-bottom-s-womq {
    margin-bottom: $s-small;
  }

  .s-top-m-womq {
    margin-top: $s-medium;
  }

  .s-bottom-m-womq {
    margin-bottom: $s-medium;
  }

  // Padding
  // .divider-padding {
  //   padding-top: $s-large;
  //   padding-bottom: $s-medium;
  // }
  .section-padding-small {
    padding-top: $s-xsmall;
    padding-bottom: $s-xsmall;
    @media (min-width: $mq-md) {
      padding-top: $s-small;
      padding-bottom: $s-small;
    }
  }

  .section-padding {
    padding-top: $s-small;
    padding-bottom: $s-small;
    @media (min-width: $mq-md) {
      padding-top: $s-medium;
      padding-bottom: $s-medium;
    }
  }

  // .main > .section-padding {
  //   // padding-top: 0; // ex deposit view
  // }
  .box-padding {
    padding-top: $s-small;
    padding-bottom: $s-small;
  }

  .basic-padding-small {
    padding: $s-small;
  }
  .basic-padding-medium {
    padding: $s-medium;
    @media (max-width: $mq-md - 1) {
      padding: $s-small $s-medium;
    }
  }

  // Visibility

  .hidden {
    display: none
  }

  /*
  * Hide only visually, but have it available for screen readers: h5bp.com/v
  */

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .mobile-only {
    @media (min-width: $mq-lg) {
      display: none;
    }
  }

  .no-padding {
    padding: 0;
  }

  /*
  * Extends the .visuallyhidden class to allow the element to be focusable
  * when navigated to via the keyboard
  */

  .visuallyhidden.focusable:active,
  .visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }

  .flex {
    display: flex;
  }
  .flex--row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .flex--column {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .flex--space {
    justify-content: 'space-between'
  }

  .wrap {
    flex-wrap: wrap;
  }

  .justify-center {
    justify-content: center;
  }

  .flex-grow {
    flex: 1;
  }
  .wide, .full-width {
    width: 100%
  }
  .half-width {
    width: 50%;
  }
  .align-items-center {
    align-items: center;
  }
  .aling-center {
    align-content: center;
  }

  .nowrap {
    white-space: nowrap;
  }
  .maxwidth {
    max-width: $text-max-width;
  }
  .maxwidth--center {
    margin: 0 auto;
  }
  .maxwidthzero {
    max-width: 0;
  }
  .gradient {
    background: linear-gradient(45deg, $c-i-purple-1 0%, $c-primary 100%);
    height: 100%;
  }

  .signicatIframe {
    width: 100%;
    height: 100%;
    border: none;
    min-height: 400px;
  }

  .signicatContainer, .full-height {
    width: 100vw;
    height: 100vh;
  }

  .border-bottom {
    border-bottom: $border-width solid $c-neutral-2;
  }

  .border-radius {
    border-radius: $b-radius;
    &--large {
      border-radius: $b-radius-large;
    }
  }

  // Other
  .select-all {
    user-select: all;
  }

  .self-stretch {
    align-self: stretch;
  }

  // make text selectable in buttons
  button.select-text {
    -webkit-user-select: all; //  Safari doesn't support user-select: text on Buttons for some reason
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

// // Make iOS fullscreen
// .vh100 {
//   height: 100vh;

//   /* iPad */
//   @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
//     height: 1024px;
//   }
//   @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
//     height: 768px;
//   }

//   /* iPhone 6, iPhone 7 */
//   @media all and (device-width: 750px) and (device-height: 1334px) and (orientation:portrait) {
//     height: 1334px;
//   }
//   @media all and (device-width: 750px) and (device-height: 1334px) and (orientation:landscape) {
//     height: 750px;
//   }

//   /* iPhone X */
//   @media all and (device-width: 375px) and (device-height: 812px) and (orientation:portrait) {
//     height: 812px;
//   }
//   @media all and (device-width: 375px) and (device-height: 812px) and (orientation:landscape) {
//     height: 375px;
//   }
// }


// /* iPhone 5
// You can also target devices with aspect ratio. */
// @media screen and (device-aspect-ratio: 40/71) {
//   .vh100 {
//       height: 500px;
//   }
// }

//Blockwidths
.block-width-medium{
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.block-width-large{
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}