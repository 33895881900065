@import 'variables';

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(20, 20);
  }
}

// Usage:
// opacity: 0;
// animation: errorReveal $t-base linear forwards;
@keyframes errorReveal {
  0% { opacity: 0 }
  20% { transform: translate3d(-2px, 0, 0); opacity: 1 }
  35% { transform: translate3d(4px, 0, 0) }
  50% { transform: translate3d(-4px, 0, 0) }
  70% { transform: translate3d(2px, 0, 0) }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    animation-timing-function: $e-out;
  }
}

@keyframes defaultReveal {
  0% {
    opacity: 0;
    transform-origin: center 100%;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// Usage:
/*
opacity: 0;
animation: fadeIn $t-base linear forwards
*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// TODO: add this to storybook and add the same helpclasses on everything
.a-fadein {
  opacity: 0;
  animation: fadeIn $t-base linear forwards;
}
.a-delay-100 { animation-delay: 100ms }
.a-delay-200 { animation-delay: 200ms }
.a-delay-300 { animation-delay: 300ms }

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(359.99deg)
  }
}

@keyframes pulse {
  0% {
    transform: scale3D(0.01,0.01,0.01); // cant animate an svg object that is invisible
  }
  100% {
    transform: scale3D(1,1,1);
  }
}

@keyframes reset {
  100% {
    opacity: 1;
    transform: translate(0) scale(1);
  }
}

// // Usage:
// // min-height: 1em;
// // animation: minHeight $t-base linear reverse forwards;
// @keyframes minHeight {
//   100% { min-height: 0 }
// }
