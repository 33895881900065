@import 'variables';

html{
  height: 100%;
}
html, body {
  min-height: 100%;
}

#root{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$headerHeightMobile});

  padding-top: $headerHeightMobile;
  // padding-bottom: $headerHeightMobile;
  @media (min-width: $mq-sm) {
    padding-top: $headerHeight;
    min-height: calc(100vh - #{$headerHeight});
    // padding-bottom: $headerHeight;
  }
}

body {
  color: $c-primary;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 81.54%, #D4D4D4 100%), #FFFFFF;
  background-image: url('../assets/left-bg.png'), url('../assets/right-bg.png');
  background-position: left bottom, right bottom;
  background-size: 50%;
  background-repeat: no-repeat;
  line-height: $f-body-height;
}

.icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: $s-small;
}

.link-button {
  display: flex;
  align-items: center;

  background: none;
  border: none;
  color: $c-primary;
}

.wrapper-content{
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $s-bodypadding-sm;

  @media (min-width: $mq-md) {
    padding: 0 $s-bodypadding-md;
  }
  @media (min-width: $mq-lg) {
    padding: 0 $s-bodypadding-lg;
  }
}

.main {
  padding: $s-small;
  display: flex;
  flex: 1;
}

.form {
  &__content {
    display: flex;
    flex-direction: column;
    @media(min-width: $mq-md){
      align-items: center;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $s-large;
  }
}