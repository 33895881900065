@import '../../styles/variables';

.footer {
  color: $c-black;
  transition: color 0.3s $e-inout;
  display: none;
  @media (orientation: landscape) {
    position: absolute;
    bottom: 0;
    left: $s-medium;
    right: 0;
    display: flex;
    align-items: center;
  }
  &__logo {
    width: auto;
    max-width: 40%;
    margin: $s-small;
    svg {
      max-width: 100%;
    }
  }
  &--relative {
    margin-top: auto;
    margin-left: $s-large;
    margin-right: $s-large;
    padding-top: $s-small;
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
  }
}
.vapormode {
  .footer {
    color: $c-white;
  }
}