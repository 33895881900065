@import '../../styles/variables';

.yesno {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 $s-small;
  max-width: 35em;

  &__button {
    border: none;
    background-color: $c-metallic-teal;
    margin: $s-small;
    margin-bottom: calc(#{$s-small} + 2vw);
    padding: 0;
    font-size: calc(3em + 4vw);
    line-height: 0;
    padding-top: 40%;
    width: 40%;
    font-weight: 900;
    font-variation-settings: 'wght' 900, 'slnt' 0;
    // text-transform: uppercase;
    border-radius: 300px;
    color: $c-white;
    position: relative;
    transition: box-shadow 0.3s $e-out, background-color 0.3s $e-out, border 0.3s linear; // border for vapormode
    &:focus {
      outline: none;
      box-shadow: 0 10px 0 0 rgba($c-primary, 0.2);
    }

    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media(max-width: $mq-md - 1){
    &__button{
      width: 30vw;
      padding-top: 30vw;
      font-size: calc(1.5em + 4vw);
    }
  }

  @media(min-width: $mq-lg){
    &__button{
      font-size: 4em;
    }
  }
}