@import '../../styles/variables';

.heading-background{
  margin: $s-small $s-medium;

  @media(orientation: portrait) {
    margin: $s-heading-margin-portrait;
  }

  h1, h2, h3, h4{
    margin-left: 0;
    margin-top: 0;
  }

  span{
    // text-transform: uppercase;
    display: inline;
    background: $c-primary;
    color: $c-white;
    padding: $s-small;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
    box-shadow: 21px 0 0 $c-primary, -$s-medium 0 0 $c-primary;

    @media(orientation: portrait) {
      padding-top: 6px;
      padding-bottom: 6px;
      box-shadow: $s-heading-margin-portrait 0 0 $c-primary, -$s-heading-margin-portrait 0 0 $c-primary;
    }
  }

  &.inverted {
    span{
      padding:  $s-small 29px;
      color: $c-primary;
      background: $c-white;
      // box-shadow: 29px 0 1px $c-white, -29px 0 0 $c-white;
      box-shadow: none;

      @media(orientation: portrait) {
        margin-top: 6px;
        margin-bottom: 6px;
        padding: $s-small $s-heading-margin-portrait;
        box-shadow: none;
        // box-shadow: $s-heading-margin-portrait 0 0 $c-white, -$s-heading-margin-portrait 0 0 $c-white;
      }
    }
  }

  & + * {
    margin-top: calc(#{$s-large} * 1.5);
  }

  & + .heading-background{
    margin-top: calc(#{$s-medium} * 1.5);
  }

  + p, + ul{
    margin-top: calc(#{$s-medium});
    @media (min-width: $mq-lg) {
      margin-top: calc(#{$s-medium} * 1.3);
    }
  }
}