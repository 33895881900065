// SPACING
// 60 och 10 ej tagna från desig
// 30, 60, 100
$s-xsmall: 7px;
$s-small: 14px;
$s-medium: 28px;
$s-large: 56px;
$s-xlarge: 112px;
// BREAKPOINTS
$mq-xs: 320px;
$mq-sm: 576px;
$mq-md: 768px;
$mq-lg: 1025px;
$mq-xl: 1900px;
// $mq-xxl: 1900px;
// Two Column Breakpoints
$mq-sm-2: $mq-sm * 2;
$mq-md-2: $mq-md * 2;
$mq-lg-2: $mq-lg * 2;
$mq-xl-2: $mq-xl * 2;
// METRICS
$width-narrow: 60rem;
$section-width: 70rem;
$content-width: 80rem;
$content-width-large: 100rem;
$menu-height-sm: 3.5rem;
$menu-height-md: 3.94rem;
$text-max-width: 50em;
$headerHeight: 100px;
$headerHeightMobile: 60px;

// Spacing
$s-small: 10px;
$s-medium: 20px;
$s-large: 40px;

$s-bodypadding-sm: $s-medium;
$s-bodypadding-md: $s-medium;
$s-bodypadding-lg: $s-large;

$s-heading-margin-portrait: 13px;

// COLORS
$c-primary: #F04E23;
$c-secondary:#666666;
$c-light: #F8F8F8;
$c-black:#000;
$c-white: #fff;
$c-metallic-teal: #435A69;
$c-neutral-1: #f7f7fa;
$c-neutral-2: #f2f2f5;
$c-neutral-3: #e8e8f0;
$c-neutral-4: #a9a9b2;
$c-neutral-5: #5f5f66;
$c-neutral-6: #222226;

// variants
$c-primary_w40: #E07885; // 40% white
$c-primary_w30: #DD6371; // 30% white
$c-primary_w20: #D94D5D; // 20% white
$c-primary_b10: #BB1F31; // 10% black
$c-primary_b20: #A71C2C; // 20% black
$c-primary_b30: #911726; // 30% black

// semantic colors
$c-danger: #FF5A38;
$c-warning: #ffc107;
$c-info: #33A9D4;
$c-success: #3FBB15;
// Illustration colors
$c-i-dark: #2F4858;
$c-i-purple-3: #495681;
$c-i-purple-2: #8A5995;
$c-i-purple-1: #CF5486;
$c-i-orange: #F76357;
// Shadows (box-shadow)
$shadow-hidden: 0px 0px 0px 0 rgba(174, 181, 216, 0.2);
$shadow-0: $shadow-hidden;
$shadow-1: 0px 2px 20px 0 rgba(174, 181, 216, 0.2); // level 1
$shadow-1-up: 0px -2px 20px 0 rgba(174, 181, 216, 0.2); // level 1 upwards
$shadow-2: 2px 4px 40px 0 rgba(174, 181, 216, 0.15); // level 2
$shadow-2-up: 2px -4px 40px 0 rgba(174, 181, 216, 0.15); // level 2 upwards
$shadow-2_5: 2px 4px 20px 0 rgba(174, 181, 216, 0.4); // level 2.5
$shadow-3: 2px 4px 40px 0 rgba(174, 181, 216, 1); // level 3
$shadow-small: 1px 4px 12px 0 rgba(174, 181, 216, 0.4); // for small ui elements that need a lift

$shadow-text-1: 0px 0px 5px rgba(0,0,0,0.5);
$shadow-text-2: 0px 0px 10px rgba(0,0,0,0.5);

// TYPOGRAPHY
$f-primary: 'Roboto', sans-serif;
$f-bold: 700;


// FONT BODY
$f-body-font: $f-primary;
$f-body-size: 18px;
$f-body-small: 14px;
$f-body-height: 1.5;
$f-body-weight: 400; // regular
$f-body-letterspacing: 0;
// Usage:
// @include f-body($align, $color);
@mixin f-body ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  // font-family: $f-body-font;
  font-size: $f-body-size;
  line-height: $f-body-height;
  font-weight: $f-body-weight;
  letter-spacing: $f-body-letterspacing;
}

@mixin f-body-small ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  // font-family: $f-body-font;
  font-size: $f-body-small;
  line-height: $f-body-height;
  font-weight: $f-body-weight;
  letter-spacing: $f-body-letterspacing;
}

@mixin headers() {
  // font-family: $f-primary;
  line-height: 1.1;
  font-weight: 700;
  font-variation-settings: 'wght' 700, 'slnt' 0;
  color: inherit;
  user-select: none;
  // overflow-wrap: break-word;
  // hyphens: auto;
  // animation: testWght 4s forwards
}
// @keyframes testWght {
//   0% {
//     font-variation-settings: 'wght' 100, 'slnt' 0;
//   }
//   100% {
//     font-variation-settings: 'wght' 900, 'slnt' 0;
//   }
// }

// H1
// Usage:
// @include header1($align, $color);
@mixin header1 ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  font-size: calc(60px + 1vw);
  line-height: 1.4;
}

// H2
// Usage:
// @include header2($align, $color);
@mixin header2 ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  font-size: calc(24px + 1vw); // 58px @ 1440
  line-height: 1.4;
}

// H3
// Usage:
// @include header2($align, $color);
@mixin header3 ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  font-size: calc(16px + 0.8vw); // 32px @ 1440
  line-height: 1.6;
}

// H4
// Usage:
// @include header2($align, $color);
@mixin header4 ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  font-size: 18px; // 18px @ 1440
  line-height: 1.6;
}

// H5
// Usage:
// @include header2($align, $color);
@mixin header5 ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  font-size: calc(12px + 1vw);
  // font-size: 14px; // 14px @ 1440
}

// H6
// Usage:
// @include header2($align, $color);
@mixin header6 ($align: null) {
  @if ($align) {
    text-align: $align;
  }
  font-size: calc(8px + 0.5vw); // 14px @ 1440
  // font-size: 14px; // 14px @ 1440
}

// ANIMATION
// Speed / duration / animation
$t-base: 0.4s;
$t-short: 0.2s;
$t-long: 0.6s;
$t-fast: $t-short;
$t-slow: $t-long;
// Easings
$e-out: cubic-bezier(0.11, 0.29, 0.18, 0.98);
$e-in: cubic-bezier(0.65, 0.01, 1, 1);
$e-inout: cubic-bezier(0.31, 0.01, 0, 0.98);
// OTHER SETTINGS
$b-width: 1px;
$b-radius: 2px;
$b-radius-large: 4px;
$b: 1px solid $c-neutral-3;

// !!!! DEPRICATED !!!!
$border-width: $b-width; // depricated
$border-radius: $b-radius;
$border-radius-large: $b-radius-large;
$border: $b;
// !!!! END DEPRICATED !!!!

@mixin border() {
  border: 1px solid $c-neutral-3;
  border-radius: $b-radius-large;
}
