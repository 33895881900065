
.line{
  fill:none;
  stroke:#FFFFFF;
  stroke-linejoin:
  round;stroke-miterlimit:10;
}

.line_small{
 fill:none;
 stroke:#FFFFFF;
 transform-origin: 50% 50%;
 stroke-linejoin:round;
 stroke-miterlimit:10;
}
.frame-wrapper{
 transform-origin: 50% 50%;
}