@import '../../styles/variables';

.endscreen {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // background: $c-white;

  z-index: 10;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  color: $c-primary;

  &--signed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: $mq-md) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - #{$headerHeight});
  }

  &__message {
    max-width: 560px;
    margin: $s-large auto;
    &.signed {
      margin: $s-large 0;
    }
  }

  &__image {
    width: 330px;
    height: 350px;
    background: #EBF7F7;
    margin: $s-large auto;
    display: flex;
    align-items: center;
    border-radius: 10px;
  }

  &__button-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media(min-width: $mq-sm){
      flex-direction: row;
    }
    .button {
      @media(min-width: $mq-sm){
        margin: 0;

        & + .button{
          margin-left: $s-medium;
        }
      }
      margin: $s-small auto;
    }
    &.signed {
      justify-content: flex-start;
    }
  }

  &__restart {
    margin: auto;
    padding: $s-small $s-medium;
    &.email-confirmation {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}