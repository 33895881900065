@import '../../styles/variables';

.multiChoice {
  --color: #{$c-primary};
  --color-inverted: #{$c-white};
  --color-secondary: #{$c-secondary};
  --color-light: #{$c-light};
  --color-opacity: #{rgba($c-primary, 0.2)};

  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  &__item {
    min-width: 150px;
    position: relative;
    background-color: var(--color-light);
    color: var(--color);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.01);
    transition: box-shadow 0.3s $e-inout, transform 0.3s $e-inout, background-color 0.3s $e-inout, color 0.3s $e-inout;
    &:hover {
      transform: translateY(2px);
      //box-shadow: 0 4px 0 0 rgba(0,0,0,0.1);
    }
    &:active, &:focus {
      outline: none;
      transform: translateY(4px);
      box-shadow: 0 0 0 4px var(--color-opacity);
    }
  }
  
  &__id {
    padding: 0.5em 0.75em;
    transition: background-color 0.3s $e-inout, color 0.3s $e-inout;
    display: flex;
    align-items: center;
    min-width: 3.2em;
  }

  &__id-circle{
    background-color: var(--color);
    color: var(--color-inverted);
    width: 100%;
    position: relative;
    width: calc(30px + 1vw);
    height: calc(30px + 1vw);
    line-height: calc(30px + 1vw);
    text-align: center;
    border-radius: 100px;
  }

  &__text {
    width: calc(100% - 100px);
    padding: 0.5em;
    z-index: 2;
    overflow-wrap: break-word;
    hyphens: auto;
    display: flex;
    align-items: center;

    .svg-checked {
      position: absolute;
      right: $s-small;
      opacity: 0;
      transition: opacity 0.4s;
      &.matched {
        opacity: 1;
        fill: white;
        stroke: #fff;
        border: 1px solid purple;
        path{
          fill: white;
          stroke: #fff;
        }
      }
    }
  }
}

.multiChoice__item + .multiChoice__item {
  margin-top: $s-medium;
  background: var(--color);
  color: var(--color-inverted);

  .multiChoice__id-circle{
    background-color: var(--color-inverted);
    color: var(--color);
  }
}

.multiChoice__item + .multiChoice__item + .multiChoice__item {
  background: var(--color-secondary);
  color: var(--color-inverted);

  .multiChoice__id-circle{
    color: var(--color-secondary);
  }
}

.vapormode .multiChoice {
  --color: #{$c-white};
  --color-inverted: #{$c-primary};
  --color-opacity: #{rgba( darken($c-primary, 20%), 0.2)};
}
