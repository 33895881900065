@import url('https://fonts.googleapis.com/css?family=Roboto');
// html { font-family: 'Work Sans', sans-serif; }

html { font-family: 'Roboto', serif; }
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Roboto', serif;
  }
}

body {
  font-variant-ligatures: contextual common-ligatures;
  font-variation-settings: 'wght' 400, 'slnt' 0;
}
// * { font-variation-settings: 'slnt' 0 }

// @font-face {
//   font-family: "Work Sans";
//   src: url("/fonts/WorkSans-Roman-VF.ttf") format("ttf"),
//     url("/fonts/WorkSans-Bold.woff2") format("woff2");
// }
// Tips: If you use a wysiwyg editor, wrap it with a fc class (Format content)

// 10,14,16,18,28,38,48,90

p + p{
  margin-top: 1.2em;
}

p + h1, p + h2, p + h3, p + h4, p + h5{
  margin-top: 2em;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6 .h6 {
  @include headers();
  + p {
    margin-top: 1.2em;
  }
}

h1, .h1 { text-transform: uppercase; }
h2, .h2 { }
h3, .h3 {  }
h4, .h4 { text-transform: uppercase; }
h5, .h5 { @include header5() }
h6, .h6 { @include header6() }

ul {
  padding-inline-start: 1.2em;
}
li {
  margin: 10px 0;
}

.h1 {
  font-size: calc(42px + 1vw); // 90px @ 1440  
  line-height: 1.3;
  @media (min-width: $mq-md) {
    @include header1();
  }
}

.h2 {
  @include header3();
  @media (min-width: $mq-md) {
    @include header2();
  }
}

.h3 {
  @include header4();
  @media (min-width: $mq-md) {
    @include header3();
  }
}

.subheading{
  @include header3();
}

// Helpclasses
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}


.link:not(.button, button), p a, a[download] {
  color: $c-primary;
  transition: $t-fast $e-out;
  transition-property: color, box-shadow;
  box-shadow: 0 0 0 0 rgba($c-primary,0.1);
  border-radius: $b-radius;
  &:focus {
    outline: rgba(0,0,0,0);
    box-shadow: 0 0 0 4px rgba($c-primary, 0.2) !important;
  }
}

.body-small{
  @include f-body-small;
}